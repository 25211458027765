export const loginStatus = (loginState) => {
  return (dispatch) => {
    dispatch({
      type: 'setLogin',
      payload: loginState
    })
  }
}

export const setUserData = (userState) => {
  return (dispatch) => {
    dispatch({
      type: 'setUserData',
      payload: userState
    })
  }
}