import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { setUserData } from "states/action-creator";


const API_URL = process.env.REACT_APP_API_URL;

function Settings() {

  const userData = useSelector(state => state.userData)
  const dispatch = useDispatch()

  const [verified, setVerified] = useState(false);

  const [formData, setFormData] = useState({
    fullname: "",
    address: "",
    phone: "",
    nationality: "",
  })

  useEffect(() => {
    setFormData({ ...formData, fullname: userData.name, address: userData.address, phone: userData.phone, nationality: userData.nationality })
    if (userData.verified === '1') {
      setVerified(true);
    }
  }, [userData])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let authtoken = localStorage.getItem('authtoken');

    try {
      const UpdateData = await fetch(`${API_URL}/update-user`, {
        method: 'POST',
        headers: {
          authorization: authtoken
        },
        body: JSON.stringify(formData)
      })
      const res = await UpdateData.json()
      if (res.status === 1) {
        let uData = {
          email: userData.email,
          name: formData.fullname,
          address: formData.address,
          phone: formData.phone,
          nationality: formData.nationality,
        };
        dispatch(setUserData(uData))
        localStorage.setItem('userdata', JSON.stringify(uData));
        toast.success("Profile updated successfully.")
      } else {
        toast.error(res.message)
      }
    } catch (error) {
      toast.error(error)
    }

  }

  return (
    <>
      <div className="pt-6 mx-auto" style={{ "maxWidth": "1024px" }}>
        <div className="w-full">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="flex justify-between">
                <div className='flex items-center'>
                  <h6 className="text-blueGray-700 text-xl font-bold mb-0">
                    Profile
                  </h6>
                  {!verified ? (
                    <span className='text-orange-600 font-semibold ml-3'><i className='far fa-times-circle mr-1'></i> Not Verified</span>) : (
                    <span className='text-emerald-600 font-semibold ml-3'><i className='far fa-check-circle mr-1'></i> Verified</span>)}
                </div>
                <Link to="/profile/orders" className="bg-indigo-500 text-white active:bg-indigo-600 hover:bg-indigo-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                  My Orders
                </Link>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={handleSubmit}>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  User Information
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="fullname">
                        Fullname
                      </label>
                      <input
                        type="text"
                        id="fullname"
                        name="fullname"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={formData.fullname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Email address
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={userData.email}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="address">
                        Address
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={formData.address}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="phone">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="nationality">
                        Nationality
                      </label>
                      <input
                        type="text"
                        id="nationality"
                        name="nationality"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={formData.nationality}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="bg-indigo-500 text-white active:bg-indigo-600 hover:bg-indigo-600 font-bold uppercase text-xs px-6 ml-4 mt-3 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                  Save
                </button>
              </form>
              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Documents
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <div className="doc-zone" style={{ "maxWidth": "300px" }}>
                      <div className="img-dropzone">
                        <img alt="" className="" src="" />
                      </div>
                      <div className="dropzone" aria-disabled="false" style={{ "position": "relative" }}>
                        <label htmlFor="doc1" className="block w-full p-2 border border-gray-500 text-center rounded">
                          <i className="fas fa-plus text-indigo-500 mr-2"></i>
                          <span className="small">Upload ID</span>
                        </label>
                        <input accept="image/*" type="file" id="doc1" name="doc1" autoComplete="off" style={{ "position": "absolute", "inset": "0px", "opacity": "1e-05", "pointerEvents": "none" }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <div className="doc-zone" style={{ "maxWidth": "300px" }}>
                      <div className="img-dropzone">
                        <img alt="" className="" src="" />
                      </div>
                      <div aria-disabled="false" style={{ "position": "relative" }}>
                        <label htmlFor="doc2" className="block w-full p-2 border border-gray-500 text-center rounded">
                          <i className="fas fa-plus text-indigo-500 mr-2"></i>
                          <span className="small">Upload ID</span>
                        </label>
                        <input accept="image/*" type="file" id="doc2" name="doc2" autoComplete="off" style={{ "position": "absolute", "inset": "0px", "opacity": "1e-05", "pointerEvents": "none" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6 mx-auto" style={{ "maxWidth": "1024px" }}>
        <div className="w-full">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="flex justify-between">
                <div className='flex items-center'>
                  <h6 className="text-blueGray-700 text-xl font-bold mb-0">
                    Account Stats
                  </h6>
                </div>
                <Link to="/profile/orders" className="bg-indigo-500 text-white active:bg-indigo-600 hover:bg-indigo-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                  Orders History
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings