import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from './states/store'
import { ToastContainer } from 'react-toastify';
import App from "App";

import 'react-toastify/dist/ReactToastify.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "style.css";


ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer theme="dark" position="bottom-center" />
  </Provider>,
  document.getElementById("root")
);