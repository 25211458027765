import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import { useSelector } from "react-redux";

// components

import Navbar from "components/Navbars/IndexNavbar.js";
import Footer from 'components/Footers/Footer'

// views

import Buy from "views/order/Buy.js";
import Sell from "views/order/Sell.js";

export default function Order() {

  return (
    <>
      <Navbar fixed />
      <main>
        <section className="relative w-full h-full min-h-screen pt-16" style={{ "paddingBottom": "340px" }}>
          <Switch>
            <Route path="/order/buy" exact component={Buy} />
            <Route path="/order/sell" exact component={Sell} />
            <Redirect from="/order" to="/" />
          </Switch>
          <Footer absolute />
        </section>
      </main>
    </>
  );
}
