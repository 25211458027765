import React, { useEffect, useState } from 'react'

function Buy() {

  const intialCryptoAmt = 0;

  const [payMethod, setPayMethod] = useState("");
  const [tax, setTax] = useState(50)
  const [buyDetails, setBuyDetails] = useState({
    fcurr_amt: 0,
    fcurr_type: 'ILS',
    ccurr_type: 'bitcoin',
  })

  const [xRate, setXrate] = useState({
    current_price: intialCryptoAmt.toFixed(2),
    symbol: "btc"
  });
  const [cryptoAmount, setCryptoAmount] = useState(0);

  const getRate = async () => {
    let url = "https://api.coingecko.com/api/v3/coins/markets?vs_currency=" + buyDetails.fcurr_type + "&ids=" + buyDetails.ccurr_type;
    const fetchRate = await fetch(url);
    const rate = await fetchRate.json();
    setXrate({ ...xRate, current_price: rate[0].current_price.toFixed(2), symbol: rate[0].symbol });
  }

  useEffect(() => {
    getRate()
    setCryptoAmount(convert(xRate.current_price))
  }, [buyDetails])

  const convert = (amt) => {
    if (buyDetails.fcurr_amt === 0) {
      return intialCryptoAmt.toFixed(8);
    }
    let rsamt = buyDetails.fcurr_amt / amt;
    return rsamt.toFixed(8);
  }

  const handleChange = async (e) => {
    setBuyDetails({ ...buyDetails, [e.target.name]: e.target.value });
  }

  const handleChangeCryptoAmount = async (e) => {
    let amt = e.target.value * xRate.current_price
    setCryptoAmount(e.target.value);
    setBuyDetails({ ...buyDetails, fcurr_amt: amt.toFixed(2) })
  }

  const handleRadio = (e) => {
    if (e.target.checked) {
      setPayMethod(e.target.value);
    }
  }

  return (
    <div className="pt-6 mx-auto" style={{ "maxWidth": "1140px" }}>
      <div className="w-full">
        <h1 className="font-bold text-2xl">Buy Cryptocurrency</h1>
        <div className="flex flex-wrap mt-6" style={{ "marginLeft": "-16px", "marginRight": "-16px" }}>
          <div className="xl:w-7/12 mb-12 xl:mb-0 px-4">
            <div className="p-6 border border-blueGray-300 rounded flex justify-center items-center">
              <div>
                <h3 className="text-xl mb-5 font-bold border-b-2 border-indigo-500 inline-block pb-2">Choose Amount</h3>
                <div className='flex flex-wrap mb-12' style={{ "marginLeft": "-16px", "marginRight": "-16px" }}>
                  <div className='w-6/12 px-4'>
                    <div className='border-b flex flex-wrap border-blueGray-400'>
                      <div className='w-6/12'>
                        <input type="number" name="fcurr_amt" id="fcurr_amt" step="0.5" min="0" className="input-2" value={buyDetails.fcurr_amt} onChange={handleChange} />
                      </div>
                      <div className='w-6/12 text-right'>
                        <select name="fcurr_type" id="fcurr_type" className='input-2' defaultValue={buyDetails.fcurr_type} onChange={handleChange}>
                          <option value="ILS">ILS</option>
                          <option value="USD">USD</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='w-6/12 px-4'>
                    <div className='border-b flex border-blueGray-400'>
                      <div className='w-6/12'>
                        <input type="number" name="ccurr_amt" id="ccurr" className="input-2" step="0.0005" min="0" value={cryptoAmount} onChange={handleChangeCryptoAmount} />
                      </div>
                      <div className='w-6/12 text-right'>
                        <select name="ccurr_type" id="ccurr_type" className='input-2' defaultValue={buyDetails.ccurr_type} onChange={handleChange}>
                          <option value="bitcoin">BTC</option>
                          <option value="ethereum">ETH</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="text-xl mb-6 font-bold border-b-2 border-indigo-500 inline-block pb-2">Payment Method</h3>
                <div className='flex flex-wrap mb-8 paymethod-box'>
                  <label htmlFor="pay_cash">
                    <input type="radio" name="pay_method" id="pay_cash" defaultValue="Cash" onChange={handleRadio} />
                    <div className='label'>
                      Cash
                    </div>
                  </label>
                  <label htmlFor="pay_card">
                    <input type="radio" name="pay_method" id="pay_card" defaultValue="Credit Card" onChange={handleRadio} />
                    <div className='label'>
                      Credit Card
                    </div>
                  </label>
                  <label htmlFor="pay_bank">
                    <input type="radio" name="pay_method" id="pay_bank" defaultValue="Bank Transfer" onChange={handleRadio} />
                    <div className='label'>
                      Bank Transfer
                    </div>
                  </label>
                </div>
                <div className='mt-4'>
                  <button className="bg-indigo-500 text-white active:bg-indigo-600 hover:bg-indigo-600 font-bold uppercase px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">Countinue</button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-5/12 px-4">
            <div className="p-6 border border-blueGray-300 rounded">
              <h3 className="text-xl mb-5 font-bold border-b-2 border-indigo-500 inline-block pb-2">Order Details</h3>
              <div>
                <h3 className='text-xl font-semibold mb-2'>You are buying</h3>
                <p className='text-lg'>{cryptoAmount} <span className='font-semibold uppercase'>{xRate.symbol}</span></p>
                <p className="text-blueGray-500 text-xs">Rate: {xRate.current_price} {buyDetails.fcurr_type} / <span className='uppercase'>{xRate.symbol}</span> </p>
              </div>
              <hr className='mt-3 mb-3 w-full border-b' />
              <div className='flex flex-basis'>
                <div className="w-6/12">
                  <h3 className='text-md text-blueGray-500 mb-2'>Payment Method:</h3>
                </div>
                <div className="w-6/12 text-right">
                  {payMethod}
                </div>
              </div>
              <div className='flex flex-basis'>
                <div className="w-6/12">
                  <h3 className='text-md text-blueGray-500 mb-2'>Coins transferred By:</h3>
                </div>
                <div className="w-6/12 text-right">
                  3 - 5 Business Days
                </div>
              </div>
              <hr className='mt-3 mb-3 w-full border-b' />
              <div className='flex flex-basis'>
                <div className="w-6/12">
                  <h3 className='text-md text-blueGray-500 mb-2'>Subtotal:</h3>
                </div>
                <div className="w-6/12 text-right">
                  {buyDetails.fcurr_amt - tax} {buyDetails.fcurr_type}
                </div>
              </div>
              <div className='flex flex-basis'>
                <div className="w-6/12">
                  <h3 className='text-md text-blueGray-500 mb-2'>Service Fee:</h3>
                </div>
                <div className="w-6/12 text-right">
                  {tax} {buyDetails.fcurr_type}
                </div>
              </div>
              <hr className='mt-3 mb-3 w-full border-b' />
              <div className='flex flex-basis'>
                <div className="w-6/12">
                  <h3 className='text-xl font-semibold mb-2'>Total:</h3>
                </div>
                <div className="w-6/12 text-right">
                  {buyDetails.fcurr_amt} {buyDetails.fcurr_type}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Buy
