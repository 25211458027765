import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { loginStatus, setUserData } from "states/action-creator";

const API_URL = process.env.REACT_APP_API_URL;

export default function Login(props) {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const redir = params.get('redir');

  let history = useHistory();
  const dispatch = useDispatch()

  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
    remember: "",
  });

  const handleChange = (e) => {
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!loginInfo.email) {
      toast.error('Please enter email')
      return;
    }

    if (!loginInfo.password) {
      toast.error('Please enter password')
      return;
    }

    let remember_me = document.getElementById('customCheckLogin')

    if (remember_me.check === true) {
      setLoginInfo({ ...loginInfo, remember: 'yes' })
    }

    try {

      const Login = await fetch(`${API_URL}/login`, {
        method: 'POST',
        body: JSON.stringify(loginInfo)
      })
      const res = await Login.json()
      localStorage.clear()
      if (res.status === 1) {
        localStorage.setItem('authtoken', res.token);
        localStorage.setItem('userdata', JSON.stringify(res.data));
        dispatch(loginStatus(true))
        dispatch(setUserData(res.data))
      }

      if (res.status === 1) {
        toast.success(res.message, { autoClose: 2000 })
        setTimeout(() => {
          if (redir) {
            history.push(redir);
          } else {
            history.push("/profile/settings")
          }
        }, 2000);
      } else {
        toast.error(res.message)
      }

    } catch (error) {
      toast.error('Failed to connect to the server, please check your internet.')
    }

  }

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Login
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      onChange={handleChange}
                      autoComplete="username"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      type="password"
                      name="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      onChange={handleChange}
                      autoComplete="current-password"
                    />
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
