import React from 'react'

function Sell() {
  return (
    <div>

    </div>
  )
}

export default Sell
