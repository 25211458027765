/*eslint-disable*/
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown";

function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const loginState = useSelector(state => state.loginStatus)

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-4 navbar-expand-lg shadow bg-white">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-bluegray-700 text-2xl font-semibold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
            >
              Crypto Bumer
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="/"
                >
                  About
                </a>
              </li>
              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="/"
                >
                  Help &amp; Support
                </a>
              </li>
              {loginState === false ?
                <>
                  <li className="flex items-center">
                    <Link to="/auth/register"
                      className="bg-indigo-500 text-white active:bg-indigo-600 hover:bg-indigo-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                    >
                      Signup
                    </Link>
                  </li>
                  <li className="flex items-center">
                    <Link to="/auth/signup"
                      className="bg-indigo-500 text-white active:bg-indigo-600 hover:bg-indigo-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                    >
                      Login
                    </Link>
                  </li>
                </>
                :
                <li className="flex items-center">
                  {/* <Link to="/myprofile"
                    className="bg-indigo-500 text-white active:bg-indigo-600 hover:bg-indigo-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  >
                    My Account
                  </Link> */}
                  <UserDropdown />
                </li>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;