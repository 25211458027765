import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// components

import Navbar from "components/Navbars/IndexNavbar.js";
import Footer from 'components/Footers/Footer'

// views

import Orders from "views/profile/Orders.js";
import Settings from "views/profile/Settings.js";

export default function Profile() {

  const loginState = useSelector(state => state.loginStatus)

  let redir = window.location.pathname

  if (!loginState) {
    return <Redirect to={`/auth/login?redir=${redir}`} />
  }

  return (
    <>
      <Navbar fixed />
      <main>
        <section className="relative w-full h-full min-h-screen pt-16" style={{ "paddingBottom": "340px" }}>
          <Switch>
            <Route path="/profile/orders" exact component={Orders} />
            <Route path="/profile/settings" exact component={Settings} />
            <Redirect from="/profile" to="/profile/settings" />
          </Switch>
          <Footer absolute />
        </section>
      </main>
    </>
  );
}
