import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { loginStatus, setUserData } from "states/action-creator";
import { useDispatch } from "react-redux";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import Profile from "layouts/Profile.js";
import Order from "layouts/Order.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile1 from "views/Profile1.js";
import Index from "views/Index.js";

const API_URL = process.env.REACT_APP_API_URL;

function App() {

  const dispatch = useDispatch()

  // Check Login State
  const checkLogin = async (authtoken) => {
    let checkLogin = await fetch(`${API_URL}/userinfo`, {
      headers: {
        authorization: authtoken
      }
    })
    let res = await checkLogin.json();
    if (res.status === 1) {
      dispatch(loginStatus(true))
      dispatch(setUserData(res.data))
      localStorage.setItem("userdata", JSON.stringify(res.data))
    } else {
      localStorage.clear()
    }
  }

  useEffect(() => {
    if (localStorage.getItem('authtoken')) {
      let authtoken = localStorage.getItem('authtoken');
      checkLogin(authtoken)
    } else {
      localStorage.clear()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        {/* add routes with layouts */}
        <Route path="/admin" component={Admin} />
        <Route path="/auth" component={Auth} />
        <Route path="/profile" component={Profile} />
        <Route path="/order" component={Order} />
        {/* add routes without layouts */}
        <Route path="/landing" exact component={Landing} />
        <Route path="/profile1" exact component={Profile1} />
        <Route path="/" exact component={Index} />
        {/* add redirect for first page */}
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  )
}

export default App
