import React from 'react'
import { Link } from 'react-router-dom'

function Orders() {
  return (
    <div className="pt-6 mx-auto" style={{ "maxWidth": "1024px" }}>
      <div className="w-full">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">
                Orders
              </h6>
              <Link to="/profile/settings" className="bg-indigo-500 text-white active:bg-indigo-600 hover:bg-indigo-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                My Profile
              </Link>
            </div>
          </div>
          <div
            className={
              "relative flex flex-col min-w-0 break-words w-full rounded bg-white"
            }
          >
            <div className="block w-full overflow-x-auto">
              {/* Projects table */}
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }
                    >
                      Project
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }
                    >
                      Budget
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }
                    >
                      Status
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }
                    >
                      Users
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }
                    >
                      Completion
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                      <img
                        src={require("assets/img/bootstrap.jpg").default}
                        className="h-12 w-12 bg-white rounded-full border"
                        alt="..."
                      ></img>{" "}
                      <span
                        className={
                          "ml-3 font-bold text-blueGray-600"
                        }
                      >
                        Argon Design System
                      </span>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      $2,500 USD
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <i className="fas fa-circle text-indigo-500 mr-2"></i> pending
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex">
                        <img
                          src={require("assets/img/team-1-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                        ></img>
                        <img
                          src={require("assets/img/team-2-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                        <img
                          src={require("assets/img/team-3-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                        <img
                          src={require("assets/img/team-4-470x470.png").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                      </div>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span className="mr-2">60%</span>
                        <div className="relative w-full">
                          <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                            <div
                              style={{ width: "60%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">

                    </td>
                  </tr>
                  <tr>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                      <img
                        src={require("assets/img/angular.jpg").default}
                        className="h-12 w-12 bg-white rounded-full border"
                        alt="..."
                      ></img>{" "}
                      <span
                        className={
                          "ml-3 font-bold text-blueGray-600"
                        }
                      >
                        Angular Now UI Kit PRO
                      </span>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      $1,800 USD
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <i className="fas fa-circle text-emerald-500 mr-2"></i>{" "}
                      completed
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex">
                        <img
                          src={require("assets/img/team-1-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                        ></img>
                        <img
                          src={require("assets/img/team-2-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                        <img
                          src={require("assets/img/team-3-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                        <img
                          src={require("assets/img/team-4-470x470.png").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                      </div>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span className="mr-2">100%</span>
                        <div className="relative w-full">
                          <div className="overflow-hidden h-2 text-xs flex rounded bg-emerald-200">
                            <div
                              style={{ width: "100%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-emerald-500"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">

                    </td>
                  </tr>
                  <tr>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                      <img
                        src={require("assets/img/sketch.jpg").default}
                        className="h-12 w-12 bg-white rounded-full border"
                        alt="..."
                      ></img>{" "}
                      <span
                        className={
                          "ml-3 font-bold text-blueGray-600"
                        }
                      >
                        Black Dashboard Sketch
                      </span>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      $3,150 USD
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <i className="fas fa-circle text-red-500 mr-2"></i> delayed
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex">
                        <img
                          src={require("assets/img/team-1-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                        ></img>
                        <img
                          src={require("assets/img/team-2-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                        <img
                          src={require("assets/img/team-3-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                        <img
                          src={require("assets/img/team-4-470x470.png").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                      </div>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span className="mr-2">73%</span>
                        <div className="relative w-full">
                          <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                            <div
                              style={{ width: "73%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">

                    </td>
                  </tr>
                  <tr>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                      <img
                        src={require("assets/img/react.jpg").default}
                        className="h-12 w-12 bg-white rounded-full border"
                        alt="..."
                      ></img>{" "}
                      <span
                        className={
                          "ml-3 font-bold text-blueGray-600"
                        }
                      >
                        React Material Dashboard
                      </span>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      $4,400 USD
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <i className="fas fa-circle text-teal-500 mr-2"></i> on
                      schedule
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex">
                        <img
                          src={require("assets/img/team-1-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                        ></img>
                        <img
                          src={require("assets/img/team-2-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                        <img
                          src={require("assets/img/team-3-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                        <img
                          src={require("assets/img/team-4-470x470.png").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                      </div>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span className="mr-2">90%</span>
                        <div className="relative w-full">
                          <div className="overflow-hidden h-2 text-xs flex rounded bg-teal-200">
                            <div
                              style={{ width: "90%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">

                    </td>
                  </tr>
                  <tr>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                      <img
                        src={require("assets/img/vue.jpg").default}
                        className="h-12 w-12 bg-white rounded-full border"
                        alt="..."
                      ></img>{" "}
                      <span
                        className={
                          "ml-3 font-bold text-blueGray-600"
                        }
                      >
                        React Material Dashboard
                      </span>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      $2,200 USD
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <i className="fas fa-circle text-emerald-500 mr-2"></i>{" "}
                      completed
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex">
                        <img
                          src={require("assets/img/team-1-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                        ></img>
                        <img
                          src={require("assets/img/team-2-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                        <img
                          src={require("assets/img/team-3-800x800.jpg").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                        <img
                          src={require("assets/img/team-4-470x470.png").default}
                          alt="..."
                          className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                        ></img>
                      </div>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span className="mr-2">100%</span>
                        <div className="relative w-full">
                          <div className="overflow-hidden h-2 text-xs flex rounded bg-emerald-200">
                            <div
                              style={{ width: "100%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-emerald-500"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Orders
