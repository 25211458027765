import React from "react";

import { createPopper } from "@popperjs/core";
import { Link } from "react-router-dom";

const UserDropdown = () => {

  const userData = JSON.parse(localStorage.getItem('userdata'))

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const Logout = () => {
    localStorage.clear();
    window.location.href = "/"
  }

  return (
    <>
      <div onMouseLeave={(e) => {
        e.preventDefault();
        if (dropdownPopoverShow)
          closeDropdownPopover()
      }}>
        <a
          href="#pablo"
          className="bg-indigo-500 text-white active:bg-indigo-600 hover:bg-indigo-600 text-xs font-bold px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
          ref={btnDropdownRef}
          onClick={(e) => {
            e.preventDefault();
          }}
          onMouseOver={(e) => {
            e.preventDefault();
            if (!dropdownPopoverShow)
              openDropdownPopover()
          }}
        >
          {userData.email} <i className="ml-1 fas fa-chevron-down"></i>
        </a>
        <div ref={popoverDropdownRef} className={(dropdownPopoverShow ? "block " : "hidden ") + "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"}>
          <Link
            to="/profile/orders"
            className={
              "text-sm py-2 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
          >
            My Orders
          </Link>
          <Link
            to="/profile/settings"
            className={
              "text-sm py-2 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
          >
            My Profile
          </Link>
          <div className="h-0 my-2 border border-t-0 border-solid border-blueGray-300" />
          <a
            href="#pablo"
            className={
              "text-sm font-bold py-2 px-4 block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
            onClick={(e) => { e.preventDefault(); Logout() }}
          >
            Logout
          </a>
        </div>
      </div>
    </>
  );
};

export default UserDropdown;
